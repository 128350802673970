import { render } from "./MayBJD.vue?vue&type=template&id=010d20ad&scoped=true"
import script from "./MayBJD.vue?vue&type=script&lang=js"
export * from "./MayBJD.vue?vue&type=script&lang=js"

import "./MayBJD.vue?vue&type=style&index=0&id=010d20ad&lang=css"
import "./MayBJD.vue?vue&type=style&index=1&id=010d20ad&scoped=true&lang=css"
import "./MayBJD.vue?vue&type=style&index=2&id=010d20ad&lang=css"
script.render = render
script.__scopeId = "data-v-010d20ad"

export default script